import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Layout } from "../components/common"
import { Button } from "react-bootstrap"
import { Link } from "gatsby"
import ContactUsBtn from "../components/common/ContactUsBtn";

let WindSolarImage = require('../images/page-images/wind-solar.jpg')

class BridgeAndInfrastructure extends Component {
    render() {
        return (
            <Layout>

                <div className="container">
                    <h1>
                        Solar Panel, Wind & Cell Tower Inspection
                    </h1>
                    <div className="row">
                        <div className="col-md-6">
                            <img className={'mbl'} src={WindSolarImage} alt=""/>
                        </div>
                        <div className="col-md-6">
                            <p>
                                We provide high resolution photography and video of power and cellular transmission infrastructure for inspection purposes.  In many cases we can provide expedited services with 24-hour turnaround.  This allows companies to make a quick assessment as to the existence and extent of damage to Wind Turbines, Cell Towers, and Solar Panels.
                            </p>

                            <p>
                                Drone inspections can be done more quickly and safely than having personnel climb towers. We can also provide live streaming of aerial inspections for real-time assessment of conditions.
                            </p>

                            <ContactUsBtn/>
                        </div>
                    </div>

                </div>
            </Layout>
        )
    }
}

BridgeAndInfrastructure.propTypes = {}

export default BridgeAndInfrastructure
